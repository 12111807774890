import React from 'react'

const footer = () => {
  return (
    <footer className="min-h-screen bg-[#E5E5E5]">
    <img src="./images/Section.png" className="mt-28 w-[1800px]" />
    <div className="bg-[#38814F]  text-white py-8">
      <div className="flex justify-between items-center ml-20">
        <h1 className="text-2xl text-white">Markhor</h1>

        <div className="flex flex-grow justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            <div>
              <h4 className="text-lg mb-2">Product</h4>
              <ul>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    Pricing
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    Overview
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    Browse
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    Accessibility <span className="text-sm">(BETA)</span>
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="text-lg mb-2">Support</h4>
              <ul>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    Developers
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    Documentation
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    Integrations
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="text-lg mb-2">Company</h4>
              <ul>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    Press
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    Events
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-gray-200 hover:text-white mb-4"
                  >
                    Request Demo
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center py-4 mt-10 ml-8 w-[1200px] border-t border-black">
        <p className="text-white text-sm ">
          © 2023 Markhor. All rights reserved.
        </p>
        <div className="space-x-4 ">
          <a href="/terms" className="text-white text-sm">
            Terms
          </a>
          <a href="/privacy" className="text-white text-sm">
            Privacy
          </a>
          <a href="/contact" className="text-white text-sm">
            Contact
          </a>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default footer
