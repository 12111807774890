import React, { useState, useEffect } from "react";
import axios from "axios";
import Icons from "./icons/Icons";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SignupForm = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    age: "",
    TID: "",
    gender: "",
    paymentReceiptURL: "",
  });
  const location = useLocation();
  const { category } = location.state || {};
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [formStep, setFormStep] = useState("1");
  const [signupEmail, setSignupEmail] = useState("");
  const [otpTokenValue, setOtpTokenValue] = useState("");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setMessage("No file selected.");
      return;
    }

    setMessage("Uploading...");

    const formDataObj = new FormData();
    formDataObj.append("file", file);

    try {
      const response = await fetch(
        "https://api.dev.civilianmarkhorpakistan.com/auth/upload-file",
        {
          method: "POST",
          body: formDataObj,
        }
      );

      if (response.ok) {
        const data = await response.json();
        setMessage("File uploaded successfully!");
        setFormData((prevData) => ({
          ...prevData,
          paymentReceiptURL: data.data.url,
        }));
      } else {
        setMessage("File upload failed.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("An error occurred during the upload.");
    }
  };

  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "").slice(0, 1);
    setOtpTokenValue((prev) => prev + e.target.value);
  };

  const handleGenderChange = (event) => {
    setFormData({ ...formData, gender: event.target.value });
  };

  useEffect(() => {
    const {
      fullName,
      email,
      phoneNumber,
      password,
      confirmPassword,
      age,
      TID,
      gender,
      paymentReceiptURL,
    } = formData;
    const isFormFilled =
      fullName &&
      email &&
      phoneNumber &&
      password &&
      confirmPassword &&
      age &&
      TID &&
      gender &&
      paymentReceiptURL;

    const isPasswordMatch = password === confirmPassword;
    setIsFormValid(isFormFilled && isPasswordMatch);

    if (password && confirmPassword && password !== confirmPassword) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  }, [formData]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.dev.civilianmarkhorpakistan.com/auth/signup",
        {
          fullName: formData.fullName,
          email: formData.email,
          password: formData.password,
          phoneNumber: formData.phoneNumber,
          userType: "WATCHER",
          paymentReceiptURL: formData.paymentReceiptURL,
          age: parseInt(formData.age),
          TID: formData.TID,
          gender: formData.gender,
        }
      );

      if (response.status === 200 || response.status === 201) {
        setSignupEmail(response.data.data.user.email);
        setFormStep("2");
      } else {
        setError("Signup failed. Please try again.");
      }
    } catch (error) {
      setError("Failed to signup. Please try again later.");
      console.error("Signup error:", error);
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();
    try {
      const otpResponse = await axios.post(
        "https://api.dev.civilianmarkhorpakistan.com/auth/email-verification",
        {
          email: signupEmail,
          token: otpTokenValue,
        }
      );

      if (otpResponse.status === 200 || otpResponse.status === 201) {
        navigate("/redirecttologin");
      } else {
        setError("OTP verification failed.");
      }
    } catch (error) {
      setError("Failed to verify OTP. Please try again.");
      console.error("OTP verification error:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-green-100 flex justify-center items-center">
      <div className="absolute top-4 left-4">
        <img
          src="/images/image11.svg"
          alt="Markhor Logo"
          className="h-12 w-12"
        />
      </div>
      {formStep === "1" && (
        <div className="z-[10]">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
            <div className="flex justify-center items-center mb-8">
              <Icons name="roundlock" />
              <h1 className="mr-3 ml-3">Signup As {category}</h1>
            </div>
            {error && (
              <div className="text-red-500 text-center mb-4">{error}</div>
            )}
            <form className="space-y-4" onSubmit={handleSubmit}>
              {/* Form Fields */}
              <div>
                <label htmlFor="email" className="block text-sm font-medium">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="name@company.com"
                  className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label htmlFor="fullName" className="block text-sm font-medium">
                  Full Name
                </label>
                <input
                  type="text"
                  id="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  placeholder="Ali"
                  className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mt-4 flex space-x-4">
                <div className="flex-1">
                  <label htmlFor="age" className="block text-sm font-medium">
                    Age
                  </label>
                  <input
                    type="number"
                    id="age"
                    value={formData.age}
                    onChange={handleChange}
                    placeholder="20"
                    className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div className="flex-1">
                  <label
                    htmlFor="phoneNumber"
                    className="block text-sm font-medium"
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    id="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder="(0000) 0000000"
                    className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-medium">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="********"
                  className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium"
                >
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="********"
                  className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label htmlFor="TID" className="block text-sm font-medium">
                  TID
                </label>
                <input
                  type="text"
                  id="TID"
                  value={formData.TID}
                  onChange={handleChange}
                  placeholder="Transaction ID"
                  className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label className="block text-sm font-medium">Gender</label>
                <div className="flex items-center space-x-4 mt-2">
                  <label>
                    <input
                      type="radio"
                      value="MALE" // Use API-expected value
                      checked={formData.gender === "MALE"}
                      onChange={handleGenderChange}
                      className="mr-2"
                    />
                    Male
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="FE_MALE" // Use API-expected value
                      checked={formData.gender === "FE_MALE"}
                      onChange={handleGenderChange}
                      className="mr-2"
                    />
                    Female
                  </label>
                </div>
              </div>
              <div>
                <label
                  htmlFor="paymentReceipt"
                  className="block text-sm font-medium"
                >
                  Upload Payment Receipt
                </label>
                <input
                  type="file"
                  id="paymentReceipt"
                  onChange={handleFileChange}
                  className="mt-2"
                />
                <p className="text-sm text-gray-500 mt-1">{message}</p>
              </div>
              <button
                type="submit"
                disabled={!isFormValid}
                className={`w-full py-2 mt-4 text-white rounded-md ${
                  isFormValid ? "bg-blue-600" : "bg-gray-400 cursor-not-allowed"
                }`}
              >
                Save
              </button>
            </form>
          </div>
        </div>
      )}
      {formStep === "2" && (
        <div className="z-[10]">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
            <h1 className="text-center text-xl font-bold mb-4">
              Verify Your Email
            </h1>
            <form onSubmit={handleOtpVerification}>
              <p className="mb-4">Enter the OTP sent to your email:</p>
              <div className="flex justify-center space-x-2">
                {[...Array(4)].map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="w-10 h-10 text-center border border-gray-300 rounded-md"
                    onInput={handleInput}
                  />
                ))}
              </div>
              <button
                type="submit"
                className="w-full py-2 mt-6 bg-blue-600 text-white rounded-md"
              >
                Verify
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignupForm;
