import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";

const Quote = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    whatsappNumber: "",
    service: "",
    projectSubject: "",
    referenceUrl: "", // Reference URL is now required
    explainRequirements: "",
    documentsUrl: "",
  });
const navigate=useNavigate()
  const [serviceCategories, setServiceCategories] = useState([]);
  const [isSaving, setIsSaving] = useState(false); // Button state
  const [message, setMessage] = useState(""); // Message for file upload status

  // Fetch service categories
  useEffect(() => {
    const fetchServiceCategories = async () => {
      try {
        const response = await fetch(
          "https://api.dev.civilianmarkhorpakistan.com/service"
        );
        const data = await response.json();
        if (data && data.data) {
          setServiceCategories(data.data);
        } else {
          console.error("Unexpected API response structure");
        }
      } catch (error) {
        console.error("Error fetching service categories:", error);
      }
    };

    fetchServiceCategories();
  }, []);

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      setMessage("No file selected.");
      return;
    }

    setMessage("Uploading...");

    const formDataObj = new FormData();
    formDataObj.append("file", file);

    try {
      const response = await fetch(
        "https://api.dev.civilianmarkhorpakistan.com/auth/upload-file",
        {
          method: "POST",
          body: formDataObj,
        }
      );

      if (response.ok) {
        const data = await response.json();
        setMessage("File uploaded successfully!");
        setFormData((prevData) => ({
          ...prevData,
          documentsUrl: data.data.url,
        }));
      } else {
        setMessage("File upload failed.");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setMessage("An error occurred during the upload.");
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    // Ensure referenceUrl is not empty before submission
    if (!formData.referenceUrl) {
      setMessage("Reference URL is required.");
      setIsSaving(false);
      return;
    }

    try {
      const response = await fetch(
        "https://api.dev.civilianmarkhorpakistan.com/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();

      if (response.ok) {
        console.log("Form submitted successfully:", result);
       navigate("/#")
        
      } else {
        console.error("Error submitting form:", result);
        alert("Failed to submit the form. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    } finally {
      setIsSaving(false);
    }
  };

  // Check if all required fields are filled
  const isFormValid = Object.keys(formData).every(
    (key) => formData[key] !== "" // Reference URL will always be checked
  );

  return (
    <div>
      <Header />

      <form
        onSubmit={handleSubmit}
        className="max-w-4xl mt-20 mx-auto p-8 bg-gradient-to-r rounded-lg shadow-lg"
      >
        <h2 className="text-3xl text-center mb-10 font-semibold text-gray-800">
          Get Quote
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-700">Service Category *</label>
            <select
              name="service"
              value={formData.service}
              onChange={handleChange}
              required
              className="w-full p-2 mt-2 border border-gray-300 rounded-md"
            >
              <option value="">Select Category</option>
              {serviceCategories.length > 0 ? (
                serviceCategories.map((category, index) => (
                  <option key={category.id} value={category.id}>
                    {category.title}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  Loading categories...
                </option>
              )}
            </select>
          </div>

          <div>
            <label className="block text-gray-700">Full Name *</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full p-2 mt-2 border border-gray-300 rounded-md"
              placeholder="Name"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-700">Email Address *</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-2 mt-2 border border-gray-300 rounded-md"
              placeholder="name@company.com"
            />
          </div>

          <div>
            <label className="block text-gray-700">Phone Number *</label>
            <input
              type="tel"
              name="whatsappNumber"
              value={formData.whatsappNumber}
              onChange={handleChange}
              required
              className="w-full p-2 mt-2 border border-gray-300 rounded-md"
              placeholder="(000) 000-0000"
            />
          </div>
        </div>

        <div>
          <label className="block text-gray-700">Project Subject *</label>
          <input
            type="text"
            name="projectSubject"
            value={formData.projectSubject}
            onChange={handleChange}
            required
            className="w-full p-2 mt-2 border border-gray-300 rounded-md"
            placeholder="Project Subject"
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-700">Reference URL *</label>
            <input
              type="url"
              name="referenceUrl"
              value={formData.referenceUrl}
              onChange={handleChange}
              required
              className="w-full p-2 mt-2 border border-gray-300 rounded-md"
              placeholder="Reference URL"
            />
          </div>

          <div>
            <label className="block text-gray-700">Upload Documents</label>
            <input
              type="file"
              name="documents"
              onChange={handleFileUpload}
              className="w-full p-2 mt-2 border border-gray-300 rounded-md"
            />
            {message && <p className="text-sm text-green-600">{message}</p>}
          </div>
        </div>
        <div>
          <label className="block text-gray-700">Explain Requirements *</label>
          <textarea
            name="explainRequirements"
            value={formData.explainRequirements}
            onChange={handleChange}
            required
            className="w-full p-2 mt-2 border border-gray-300 rounded-md"
            rows="4"
            placeholder="Write description"
          ></textarea>
        </div>

        <div className="flex justify-center mt-6">
          <button
            type="submit"
            className={`w-1/2 py-2 px-4 font-semibold rounded-md focus:outline-none ${
              isFormValid && !isSaving
                ? "bg-green-600 text-white hover:bg-green-700"
                : "bg-gray-400 text-gray-200 cursor-not-allowed"
            }`}
            disabled={!isFormValid || isSaving}
          >
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </form>

      <Footer />
    </div>
  );
};

export default Quote;
