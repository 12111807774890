import React, { useState } from "react";
import Icons from "./icons/Icons";
import SubDashboard from "./SubDashboard"; // Assuming SubDashboard is for Dashboard content
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [activePage, setActivePage] = useState("dashboard");
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear the authentication token
    localStorage.removeItem("accessToken");

    // Redirect to the login page
    navigate("/login");
  };

  return (
    <div className="flex bg-gray-100 min-h-screen">
      {/* Sidebar */}
      <div className="w-56 bg-white p-4 h-screen fixed top-0 left-0">
        <div className="flex items-center mb-10">
          <img
            src="/images/image11.svg"
            alt="Markhor Logo"
            className="h-12 w-12 ms-2"
          />
          <Icons name="leftArrow" />
        </div>
        <div>
          <ul>
            <li className="mb-4">
              <a
                href="#"
                onClick={() => setActivePage("dashboard")}
                className={`flex items-center space-x-2 ${
                  activePage === "dashboard" ? "font-bold text-blue-500" : ""
                }`}
              >
                <Icons name="dashboardicon" />
                <span>Dashboard</span>
              </a>
            </li>
            <li className="mb-4">
              <a
                href="#"
                onClick={() => setActivePage("tiktok")}
                className={`flex items-center space-x-2 ${
                  activePage === "tiktok" ? "font-bold text-blue-500" : ""
                }`}
              >
                <Icons name="tiktok" />
                <span>Tiktok</span>
              </a>
            </li>
            <li className="mb-4">
              <a
                href="#"
                onClick={() => setActivePage("instagram")}
                className={`flex items-center space-x-2 ${
                  activePage === "instagram" ? "font-bold text-blue-500" : ""
                }`}
              >
                <Icons name="instagram" />
                <span>Instagram</span>
              </a>
            </li>
            <li className="mb-4">
              <a
                href="#"
                onClick={() => setActivePage("youtube")}
                className={`flex items-center space-x-2 ${
                  activePage === "youtube" ? "font-bold text-blue-500" : ""
                }`}
              >
                <Icons name="youtube" />
                <span>Youtube</span>
              </a>
            </li>
            <li className="mb-4">
              <a
                href="#"
                onClick={() => setActivePage("twitter")}
                className={`flex items-center space-x-2 ${
                  activePage === "twitter" ? "font-bold text-blue-500" : ""
                }`}
              >
                <Icons name="x" />
                <span>Twitter</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-auto">
          {/* Logout Button */}
          <button
            className="w-full mt-96 bg-red-500 text-white py-2 px-2 rounded-md hover:bg-red-600 transition"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>

      {/* Content Area */}
      <div className="flex-1 ml-56">
        {/* Fixed Header */}
        <header className="fixed top-0 left-56 right-0 border border-white bg-white p-3 z-10 flex ms-1">
          <h2 className="text-2xl font-bold">
            {activePage.charAt(0).toUpperCase() + activePage.slice(1)}
          </h2>
          <Icons name="notification" />
        </header>

        {/* Scrollable Content */}
        <div className="mt-14 px-4 h-[calc(100vh-4rem)] overflow-y-auto">
          {activePage === "dashboard" && <SubDashboard />}
          {activePage === "tiktok" && <div>Tiktok Content</div>}
          {activePage === "instagram" && <div>Instagram Content</div>}
          {activePage === "youtube" && <div>Youtube Content</div>}
          {activePage === "twitter" && <div>Twitter Content</div>}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
