import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import CategorySelection from "./CategorySelection";
import SignupForm from "./SignupForm";
import RedirectToLogin from "./RedirectToLogin";
import Login from "./Login";
import Dashboard from "./Dashboard";
import Landing from "./Landing";
import Quote from "./Quote";
function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/quote" element={<Quote />} />
        <Route path="/category" element={<CategorySelection />} />
        <Route path="/signup" element={<SignupForm />} />
        <Route path="/redirecttologin" element={<RedirectToLogin />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Router>
  );
}

export default App;
