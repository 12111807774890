import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import "@fortawesome/fontawesome-free/css/all.min.css";

function Land() {
  const [open, setOpen] = useState(null);

  const toggleFAQ = (index) => {
    setOpen(open === index ? null : index);
  };
  const handleQuietClick = () => {
    navigate("/quote");
  };
  const handleWatcherClick = () => {
    navigate("/category");
  };
  const handleExpertClick = () => {
    navigate("/category");
  };
  const handleCreatorClick = () => {
    navigate("/category");
  };
  const slides = [
    {
      image: "./images/Food.png",
      title: "Watcher",
      price: "PKR 999",
      description: "Monthly basis",
    },
    {
      image: "./images/Food.png",
      title: "Expert",
      price: "PKR 999",
      description: "Monthly basis",
    },
    {
      image: "./images/Food.png",
      title: "Expert",
      price: "PKR 999",
      description: "Monthly basis",
    },
  ];

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handleArrowClick = (direction) => {
    if (direction === "left") {
      if (currentSlideIndex > 0)
        setCurrentSlideIndex((prevIndex) => prevIndex - 1);
    } else if (direction === "right") {
      if (currentSlideIndex < slides.length - 1)
        setCurrentSlideIndex((prevIndex) => prevIndex + 1);
    }
  };
  const navigate = useNavigate();

  const faqItems = [
    "What are the various disciplines offered?",
    "What is the maximum duration of programmes offered?",
    "What are the various CA Programmes offered?",
    "What are the various CA Programmes offered?",
  ];
  const services = [
    {
      title: "Social Media Marketing",
      description:
        "Elevate your brand with our expert social media marketing services.",
      image: "./images/64x64_01 2.png",
      bgColor: "bg-gray-100",
      textColor: "text-gray-700",
      descriptionTextColor: "text-gray-700",
    },
    {
      title: "Social Monetization",
      description:
        "Expert in maximizing your online presence and converting into revenue.",
      image: "./images/Group.png",
      bgColor: "bg-green-700",
      textColor: "text-gray-300",
      descriptionTextColor: "text-gray-300",
    },
    {
      title: "SEO",
      description:
        "Expert in SEO ranking and optimization services of all social media platforms.",
      image: "./images/Group(2).png",
      bgColor: "bg-gray-100",
      textColor: "text-gray-700",
      descriptionTextColor: "text-gray-700",
    },
    {
      title: "Digital Marketing",
      description:
        "We turn online presence into profit through expert digital strategies.",
      image: "./images/64x64_04 2.png",
      bgColor: "bg-gray-100",
      textColor: "text-gray-700",
      descriptionTextColor: "text-gray-700",
    },
    {
      title: "On Demand",
      description:
        "For any type of on-demand social media and IT services you can contact us.",
      image: "./images/Group(1).png",
      bgColor: "bg-gray-100",
      textColor: "text-gray-700",
      descriptionTextColor: "text-gray-700",
    },
  ];
  const features = [
    { icon: "💚", title: "Urgent Services" },
    { icon: "✅", title: "Refund Guaranteed" },
    { icon: "💵", title: "Fair Prices" },
    { icon: "📞", title: "24/7 Support", customColor: true },
  ];
  const card = [
    {
      title: "Digital Advertising",
      description: "Generate new leads through multiple channels",
      image: "./images/108x108_05.png",
      bgColor: "bg-gray-100",

      descriptionTextColor: "text-gray-700",
    },
    {
      title: "Increase efficiency ",
      description: "Be a part of white label apps to boost your brand further",
      image: "./images/108x108_06.png",
      bgColor: "bg-gray-100",
      textColor: "text-gray-700",
      descriptionTextColor: "text-gray-700",
    },
    {
      title: "Data Analysis",
      description: "Know your customers’ profiles, wants & needs",
      image: "./images/108x108_03.png",
      bgColor: "bg-gray-100",
      textColor: "text-gray-700",
      descriptionTextColor: "text-gray-700",
    },
    {
      title: "Data Analysis",
      description: "Know your customers’ profiles, wants & needs",
      image: "./images/108x108_03(2).png",
      bgColor: "bg-gray-100",
      textColor: "text-gray-700",
      descriptionTextColor: "text-gray-700",
    },
  ];
  return (
    <div className="min-h-screen">
      {/* <img
        src="./images/Ellipse 36.png"
        alt="Partner 1"
        className=" w-[700px] absolute "
      /> */}

      {/* <img className=" absolute" src="./images/Ellipse 37.png" />  */}
      <Header />

      <main>
        <section className="flex justify-between items-start text-blackrounded-b-lg p-8 mx-10">
          <div className="w-96 ml-16 position-relative ">
            <h2 className="text-5xl ml-10 font-bold  font-serif">
              We’re here to Increase your Productivity
            </h2>
            <svg width="400" height="100" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M50 50 Q200 10 350 50"
                stroke="green"
                stroke-width="4"
                fill="none"
              />
            </svg>

            <p className="text-lg ml-10 ">
              Let's make your work more organize and easily using the Taskio
              Dashboard with many of the latest featuresin managing work every
              day.
            </p>
          </div>
          <div className="ml-56 mr-28 position-absolute  p-2 rounded-lg">
            <img
              src="./images/Frame 46.png"
              alt="Partner 1"
              className=" w-[500px] h-[500px] "
            />
          </div>
        </section>
        <div className="flex">
          <button
            className="bg-green-800  text-white ml-16 px-4 py-3 rounded-full  hover:bg-green-700 cursor-pointer"
            onClick={handleQuietClick}
          >
            Get Quote
          </button>

          <div className="flex ml-10 items-center">
            <img src="./images/Play.png" className="w-9" />
            <button className="text-black px-6 py-2  text-xl">View Demo</button>
          </div>
        </div>

        {/* <img src="./images/Vector(1).png" className="absolute ml-96 -mt-72" /> */}
        {/* <img src="./images/Ellipse 1.png" className="absolute -mt-96" /> */}

        <section className="mt-12 gap-16">
          <h2 className="text-3xl text-center mb-8 font-bold">
            More than 25,000 teams use Collabs
          </h2>
          <div className="flex items-center justify-center gap-24 text-2xl text-gray-500">
            <div className="flex items-center gap-2">
              <i className="fas fa-image"></i> Unsplash
            </div>
            <div className="flex items-center gap-2">
              <i className="fas fa-box"></i> Notion
            </div>
            <div className="flex items-center gap-2">
              <i className="fas fa-comment-dots"></i> INTERCOM
            </div>
            <div className="flex items-center gap-2">
              <i className="fas fa-microphone"></i> descript
            </div>
            <div className="flex items-center gap-2">
              <i className="fas fa-spell-check"></i> grammarly
            </div>
          </div>
        </section>

        <div className="bg-gradient-to-b from-white mt-28 to-green-50 py-12 px-6">
          <div className="max-w-7xl text-left mx-auto">
            <h2 className="text-2xl font-semibold text-green-800 mb-6">
              How we can help our partners
            </h2>
            <p className="text-gray-600 mb-10">
              The things we've made happen that work a little too well.
            </p>
            <div className="flex justify-center gap-6 flex-wrap">
              {services.map((service, index) => (
                <div
                  key={index}
                  className={`p-6 rounded-lg shadow-md ${service.bgColor} flex flex-col justify-center items-center w-full sm:w-72 lg:w-80`}
                >
                  <img
                    src={service.image}
                    alt={service.title}
                    className="text-4xl mb-4 w-12 h-12 mr-auto"
                  />
                  <h3 className={`text-lg ${service.textColor} text-center `}>
                    {service.title}
                  </h3>
                  <p
                    className={`${service.descriptionTextColor} mt-2 text-center `}
                  >
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
      {/* <img className=" ml-11  absolute" src="./images/Ellipse 40.png" /> */}

      <div className="flex items-center mt-16 mb-10 justify-center">
        <div className="flex gap-20">
          {features.slice(0, 2).map((feature, index) => (
            <div
              key={index}
              className="flex items-center gap-2 px-4 py-3 bg-white border border-gray-200 rounded-md"
            >
              <span className="text-green-500 text-lg">{feature.icon}</span>
              <p className="text-gray-800 font-medium">{feature.title}</p>
            </div>
          ))}
        </div>

        <div className="flex gap-20 ml-16">
          {features.slice(2).map((feature, index) => (
            <div
              key={index}
              className="flex items-center gap-2 px-4 py-3 bg-white border border-gray-200 rounded-md"
            >
              <span className="text-green-500 text-lg">{feature.icon}</span>
              <p className="text-gray-800 font-medium">{feature.title}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="w-24   ml-20 h-10  border-t-4 border-green-800"></div>

      <h2 className="text-2xl ml-20  text-gray-600 mb-4">Management Tool</h2>
      <p className="text-gray-600 ml-20">Let’s bring your strategies to life</p>
      <div className="flex justify-center gap-6 mt-10 ">
        {card.map((service, index) => (
          <div
            key={index}
            className={`p-6 rounded-lg shadow-md ${service.bgColor} flex flex-col justify-center items-center text-center w-64 h-72`}
          >
            <img
              src={service.image}
              alt={service.title}
              className="mb-4 w-16 h-16"
            />
            <h3 className={`text-lg ${service.textColor} `}>{service.title}</h3>
            <p className={`${service.descriptionTextColor} mt-2`}>
              {service.description}
            </p>
          </div>
        ))}
      </div>

      <div>
        <h1 className="text-4xl font-bold text-center mt-16">
          Choose Plan <br></br>
          That’s Right For You{" "}
        </h1>
        <p className="text-center mt-4 text-gray-400">
          {" "}
          Choose plan that works best for you, feel free to contact us
        </p>
      </div>
      <div className="min-h-screen mt-20">
        <div className="flex justify-center gap-8 py- px-6">
          <div className="bg-green-700  rounded-lg shadow-lg p-6 text-center">
            <h2 className="text-white text-2xl font-semibold mb-4">Watcher</h2>

            <p className="text-xl font-bold text-gray-300 mb-4">
              Rs <span className="text-4xl text-white">999</span>
            </p>

            <div
              className="px-4 py-20 rounded-lg"
              style={{ backgroundColor: "#F8F8F8" }}
            >
              <ul className="space-y-2 mb-11">
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span>
                  Unskilled content watcher.
                </li>
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span>
                  Monitor and review online <br></br> content
                </li>
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span>
                  Ensure content aligns <br></br> with specified guideline
                </li>
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span>
                  Follow guidelines <br></br> provided by the admin.
                </li>
              </ul>
              <a
                href="#"
                className="bg-green-800 text-white py-3 px-16 rounded-lg hover:bg-green-600 transition duration-300"
                onClick={handleWatcherClick}
              >
                Sign up as watcher
              </a>
            </div>
          </div>

          <div className="bg-green-700 rounded-lg shadow-lg p-6 text-center">
            <h2 className="text-white text-2xl font-semibold mb-4">Creator</h2>

            <p className="text-xl font-bold text-gray-300 mb-4">
              Rs <span className="text-4xl text-white">9999</span>
            </p>
            <div
              className="px-4 py-20 rounded-lg"
              style={{ backgroundColor: "#F8F8F8" }}
            >
              <ul className="space-y-2 text-left">
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span> Create engaging
                  and relevant content <br></br> for social media platforms.
                </li>
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span> Contribute to
                  brand awareness <br></br> and audience engagement.
                </li>
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span> Educated
                  individuals with skills in <br></br>creative writing and
                  digital media.
                </li>
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span> Earn passive
                  income by producing <br></br>quality content for paid
                  campaigns.
                </li>
              </ul>
              <a
                href="#"
                className="bg-green-800 text-white py-3 px-16 rounded-lg hover:bg-green-600 transition duration-300 mt-6 inline-block"
                onClick={handleCreatorClick}
              >
                Sign up as Creator
              </a>
            </div>
          </div>
          <div className="bg-green-700 rounded-lg shadow-lg p-6 text-center relative">
            <h2 className="text-2xl font-semibold mb-4 text-white">Expert</h2>

            <p className="text-xl font-bold text-gray-300 mb-4">
              Rs <span className="text-4xl text-white">18,999</span>
            </p>

            <div className="px-4 py-16 bg-white rounded-lg relative">
              <ul className="space-y-2 text-left mb-14">
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span> SEO
                </li>
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span> Content
                  marketing
                </li>
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span> Social media
                  management
                </li>
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span> Web development
                </li>
                <li className="flex items-center">
                  <span className="text-green-700 mr-2">✔</span> Other
                  specialized areas
                </li>
              </ul>
              <a
                href="#"
                className="bg-green-800 text-white py-3 px-16 rounded-lg hover:bg-green-600 transition duration-300 mt-6 inline-block"
                onClick={handleExpertClick}
              >
                Sign up as Expert
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 mt-32 mb-28 font-sans">
        <div className="ml-36 text-left ">
          <p className="text-2xl text-gray-700 pt-10 ">
            {" "}
            What our partners say about <br />
            <span className="text-green-700">Markhor Civilian</span>
          </p>
        </div>
        <div className="flex">
          <div className="max-w-md ml-60 mr-20 mt-20 p-6 bg-white rounded-lg shadow-lg border border-gray-200 mb-20">
            <div className="flex justify-between items-center mb-6">
              <div className="flex flex-col">
                <h2 className="text-xl font-semibold text-gray-800">
                  Mohammad Sharkawi
                </h2>
                <span className="text-sm text-gray-500">
                  Operations Manager
                </span>
              </div>
              <img
                src="./images/image 5.png"
                alt="Partner 4"
                className="w-32 h-20 object-cover"
              />
            </div>
            <div className="flex flex-row space-x-3 py-3">
              <img src="./images/ggg.png" alt="Partner 4" className="w-5 h-5" />
              <p className="text-gray-600 text-sm leading-relaxed">
                Our partnership contributed to growth in footfall & revenue
                compared to any other platform. That’s why we’re always the
                first one to touch base whenever we open new outlets and we’re
                looking forward to more campaigns.
              </p>
            </div>
          </div>

          <div className="max-w-md mt-20 p-6 bg-white rounded-lg shadow-lg border border-gray-200 mb-20">
            <div className="flex justify-between items-center mb-6">
              <div className="flex flex-col">
                <h2 className="text-xl font-semibold text-gray-800">Eddie</h2>
                <span className="text-sm text-gray-500">Operations Head</span>
              </div>
              <img
                src="./images/little bangkok.png"
                alt="Partner 4"
                className="w-32 h-20 object-cover"
              />
            </div>
            <div className="flex flex-row space-x-3 py-3">
              <img src="./images/ggg.png" alt="Partner 4" className="w-5 h-5" />
              <p className="text-gray-600 text-sm leading-relaxed">
                The Markhor has been very helpful for our business during the
                pandemic and it’s performing quite well. We’ve been lucky to be
                dealing with Shailja – she keeps us updated about our brand
                performance.
              </p>
            </div>
          </div>
        </div>
        <img src="./images/3 buttons(1).png" className="mx-auto  " />
      </div>

      <div className="relative">
        <div className="border relative border-green-600 bg-green-600 rounded-r-2xl w-[1000px] h-[450px] mb-14">
          <h1 className="text-4xl font-sans text-white ml-24 mt-28">
            Start growing with <br />
            <span className="text-yellow-300">Markhor Civilian today</span>
          </h1>

          <p className="ml-24 text-white mt-10">
            Feeling inspired? We’d love to hear from you!
          </p>

          <button className="text-white border border-green-400 bg-green-400 p-3 rounded ml-24 mt-12">
            Join us now
          </button>
        </div>
        <img
          src="./images/Businessman.png"
          alt="Partner 4"
          className="absolute right-0 bottom-0 mb-6 mr-6 w-[700px] h-[400px] object-contain"
        />
      </div>
      {/* <img
        src="./images/Ellipse 2.png"
        className="absolute ml-[503px] -mt-[700px]"
      /> */}
      <div className="w-24 h-10 ml-24 border-t-4 border-green-800"></div>

      <h2 className="text-2xl font-bold text-gray-800 mb-4 ml-24">FAQs</h2>
      <p className="text-gray-600 mb-6 ml-24">
        Let’s bring your strategies to life
      </p>
      <div className="space-y-4">
        {faqItems.map((item, index) => (
          <div
            key={index}
            className="border border-green-500 ml-24 mr-24 rounded-md p-4 cursor-pointer flex items-center"
            onClick={() => toggleFAQ(index)}
          >
            <input
              type="checkbox"
              className="mr-4 w-5 h-5 text-green-600 border-gray-300 rounded focus:ring-green-500"
            />
            <div className="flex-1">
              <div className="flex justify-between items-center">
                <span className="text-gray-700 font-medium">{item}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-6 w-6 transform transition-transform ${
                    open === index ? "rotate-180" : ""
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
              {/* FAQ Answer */}
              {/* {open === index && (
                <p className="mt-4 text-gray-600">
                  This is the answer for the question.
                </p>
              )} */}
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}

export default Land;
