import React, { useEffect, useState } from "react";
import Icons from "./icons/Icons";
import { Link, useNavigate } from "react-router-dom";
import TaskSubmitModal from "./TaskSubmitModal";
const SubDashboard = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const navigate = useNavigate();

  // Fetch tasks on component mount
  useEffect(() => {
    let isMounted = true; // Avoid state updates on unmounted component

    const fetchTasks = async () => {
      const token = localStorage.getItem("accessToken"); // Retrieve the token from localStorage

      if (!token) {
        setError("Authentication token is missing. Please log in again.");
        setLoading(false);
        navigate("/login"); // Redirect to login if no token is found
        return;
      }

      try {
        const response = await fetch(
          "https://api.dev.civilianmarkhorpakistan.com/user-tasks",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token, // Include the token in the Authorization header
            },
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch tasks");
        }

        const data = await response.json();
        if (isMounted) setTasks(data.data || []); // Update tasks only if the component is still mounted
      } catch (err) {
        if (isMounted) setError(err.message);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchTasks();

    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, [navigate]);
  const handleTaskClick = (task) => {
    setSelectedTask(task); // Set the clicked task as selected
  };

  const closeModal = () => {
    setSelectedTask(null); // Close the modal by clearing the selected task
  };
  return (
    <div>
      <div className="mb-6 mt-6 ms-1 flex">
        <h2 className="text-2xl font-semibold">Welcome, Ahmed</h2>
        <div className="relative inline-block w-64 text-left ml-auto mr-10">
          <button className="inline-flex justify-between w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            This Month
            <svg
              className="-mr-1 ml-2 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06 0L10 10.27l3.71-3.06a.75.75 0 111.02 1.1l-4.25 3.5a.75.75 0 01-.94 0l-4.25-3.5a.75.75 0 010-1.1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-6 mb-8 w-3/4">
        {/* Card 1 */}
        <div className="bg-white p-4 rounded-lg shadow">
          <p className="text-2xl font-bold text-[#8EC4AC]">2</p>
          <h3 className="text-sm mt-1">Open Tasks</h3>
        </div>

        {/* Card 2 */}
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between items-center">
            <p className="text-2xl font-bold text-[#F9AC17]">8</p>
            <Icons name="activetask" />
          </div>
          <h3 className="text-sm mt-1">Active Task</h3>
        </div>

        {/* Card 3 */}
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between items-center">
            <p className="text-2xl font-bold text-[#8EC4AC]">45</p>
            <Icons name="totaltask" />
          </div>
          <h3 className="text-sm mt-1">Total Tasks</h3>
        </div>

        {/* Card 4 */}
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between items-center">
            <p className="text-2xl font-bold text-[#8EC4AC]">40:00:05</p>
            <Icons name="clock" />
          </div>
          <h3 className="text-sm mt-1">Worked this week</h3>
        </div>

        {/* Card 5 */}
        <div className="bg-white p-4 rounded-lg shadow">
          <p className="text-2xl font-bold text-yellow-500">1000 Rs</p>
          <h3 className="text-sm mt-1">Total Payment</h3>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg shadow">
        <div className="flex ml-auto">
          <h3 className="text-lg font-semibold mb-4">Recent Tasks</h3>
          <h3
            className="text-lg font-semibold mb-4 ml-auto"
            style={{ color: "#008349" }}
          >
            View All
          </h3>
        </div>

        {loading ? (
          <p>Loading tasks...</p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <table className="min-w-full">
            <tbody>
              {tasks.map((task, index) => (
                <tr key={index} className="border-b border-gray-300">
                  <td className="p-2">
                    <div className="flex items-center">
                      <div className="border border-gray-100 bg-gray-100 p-3 rounded-3xl">
                        <img
                          src={task.service?.thumbnailUrl || ""}
                          alt={task.service?.title || "Service Thumbnail"}
                          className="w-10 h-10 object-cover rounded-full"
                        />
                      </div>
                      <span
                        className="ml-2 font-bold cursor-pointer"
                        onClick={() => handleTaskClick(task)}
                      >
                        {task.taskTitle}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <p className="ml-14 text-sm" style={{ color: "#808080" }}>
                        {task.description || "No description available"}
                      </p>
                    </div>
                  </td>
                  <td className="p-2 font-bold">
                    <Link to={task.taskUrl} className="p-2 font-normal text-blue-500">{task.taskUrl}</Link>
                    <div
                      className="flex flex-col font-normal"
                      style={{ color: "#808080" }}
                    >
                      <p>Task Url</p>
                    </div>
                  </td>
                  <td className="p-2 font-bold">
                    {task.service?.description || "No description available"}
                    <div
                      className="flex flex-col font-normal"
                      style={{ color: "#808080" }}
                    >
                      <p>Description</p>
                    </div>
                  </td>
                  <td className="p-2 font-bold">
                    {task.taskPrice}
                    <div
                      className="flex flex-col font-normal"
                      style={{ color: "#808080" }}
                    >
                      <p>Task Price</p>
                    </div>
                  </td>
                  <td
                    className="p-2 font-bold"
                    style={{
                      color: task.status === "Pending" ? "#38804F" : "#FF6A6A",
                    }}
                  >
                    {task.status}
                  </td>
                  <div className="cursor-pointer" onClick={() => handleTaskClick(task)}>
                    <Icons name="verticaldot" />
                  </div>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {selectedTask && (
        <TaskSubmitModal task={selectedTask} onClose={closeModal} />
      )}
    </div>
  );
};

export default SubDashboard;
