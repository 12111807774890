import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Icons from './icons/Icons';

const CategorySelection = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const navigate = useNavigate(); // Hook to navigate programmatically

  const categories = [
    {
      id: 1,
      name: "Watcher",
      description: "Personal account to manage all your activities.",
      bgColor: "bg-green-100",
      svg: <Icons name="watcher" />,
    },
    {
      id: 2,
      name: "Creator",
      description: "Own or belong to a company, this is for you.",
      bgColor: "bg-gray-100",
      svg: <Icons name="creator" />,
    },
    {
      id: 3,
      name: "Expert",
      description: "Own or belong to a company, this is for you.",
      bgColor: "bg-gray-100",
      svg: <Icons name="watcher" />,
    },
  ];

  const handleCategoryClick = (id, name) => {
    setSelectedCategory({ id, name }); // Store both id and name
  };

  const handleNextClick = () => {
    if (selectedCategory) {
      // Navigate to signup and pass selected category name
      navigate("/signup", { state: { category: selectedCategory.name } });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-green-100 flex justify-center items-center">
      <div className="absolute top-4 left-4">
        <img
          src="/images/image11.svg"
          alt="Markhor Logo"
          className="h-12 w-12"
        />
      </div>
      <div className="z-[10]">
        <div className="w-full max-w-md mx-auto p-6 bg-white shadow-lg rounded-lg">
          <div className="flex justify-center items-center mb-8">
            <Icons name="roundlock" />
            <h2 className="text-center text-2xl font-semibold text-gray-700 ms-3">
              Category
            </h2>
          </div>

          <div className="space-y-4">
            {categories.map((category) => (
              <div
                key={category.id}
                className={`border rounded-lg p-4 flex items-center justify-between cursor-pointer transition duration-200 
                            ${
                              selectedCategory?.id === category.id
                                ? "bg-green-200"
                                : "hover:bg-green-100 hover:border-[#38804F]"
                            }`}
                onClick={() => handleCategoryClick(category.id, category.name)}
                role="button"
                aria-label={`Select ${category.name}`}
              >
                <div className="flex items-center space-x-2">
                  {category.svg}
                  <div>
                    <h3 className="text-lg font-semibold text-gray-800">
                      {category.name}
                    </h3>
                    <p className="text-sm text-gray-500">
                      {category.description}
                    </p>
                  </div>
                </div>
                <Icons name="leftArrow" />
              </div>
            ))}
          </div>
          <button
            onClick={handleNextClick}
            className={`w-full mt-6 py-3 text-white rounded-lg transition ${
              selectedCategory === ""
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-600 cursor-pointer"
            }`}
            style={{
              background:
                selectedCategory === ""
                  ? "gray"
                  : "linear-gradient(90.6deg, #38804F 0%, #1F5464 99.48%)",
            }}
            disabled={!selectedCategory}
          >
            Next
          </button>
        </div>
        <p className="text-center mt-6 text-sm text-gray-500">
          Already have an account?{" "}
          <a href="/login" className="text-green-600 font-semibold">
            LogIn
          </a>
        </p>
      </div>
      <div className="absolute bottom-4 right-2 z-[1]">
        <Icons name="union" />
      </div>
    </div>
  );
};

export default CategorySelection;
