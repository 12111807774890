import React from "react";
import Icons from "./icons/Icons";
import { Link } from "react-router-dom";

const RedirectToLogin = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-green-100 flex justify-center items-center">
      <div className="absolute top-4 left-4">
        <img
          src="/images/image11.svg"
          alt="Markhor Logo"
          className="h-12 w-12"
        />
      </div>
      <div className="z-[10]">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
          <form className="space-y-4">
            <p className="text-center font-semibold text-[#868686]">
              You have successfully create your account!
            </p>
            <h4 className="text-center text-[#868686]">Welcome</h4>
            <div className="grid grid-cols-4 gap-4"></div>

            <div className="flex justify-center items-center mt-4">
              <Icons name="welcome" />
            </div>
            <Link to="/login">
              <button
                className="w-full mt-6 py-3 text-white bg-green-500 rounded-lg hover:bg-green-600 transition"
                style={{
                  background:
                    "linear-gradient(90.6deg, #38804F 0%, #1F5464 99.48%)",
                }}
              >
                Go To Login
              </button>
            </Link>
          </form>
        </div>
      </div>
      {/* union svg */}
      <div className="absolute bottom-4 right-2 z-[1]">
        <Icons name="union" />
      </div>
    </div>
  );
};

export default RedirectToLogin;
