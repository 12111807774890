import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleSignUpClick = () => {
    navigate('/category');
  };

  return (
    <header className="bg-white text-black p-4 rounded-t-lg flex flex-wrap items-center justify-between md:flex-nowrap">
      {/* Logo */}
      <div className="flex items-center">
        <img
          src="./images/image 11.png"
          alt="Company Logo"
          className="h-12 w-12 md:h-16 md:w-16 mr-2"
        />
      </div>

      {/* Navigation Links */}
      <nav className="flex-1  flex flex-wrap gap-4 justify-center md:justify-center md:gap-6 text-sm md:text-base">
        <Link to="/" className="text-black hover:text-white">
          Home
        </Link>
        <Link to="/services" className="text-black hover:text-white">
          Services
        </Link>
        <Link to="/faq" className="text-black hover:text-white">
          FAQ
        </Link>
        <Link to="/blog" className="text-black hover:text-white">
          Blog
        </Link>
        <Link to="/about" className="text-black hover:text-white">
          About Us
        </Link>
      </nav>

      {/* Buttons */}
      <div className="flex items-center gap-2 mt-4 md:mt-0">
        <button
          className="text-black px-4 py-1 border border-white rounded-xl flex items-center justify-center hover:bg-green-600 hover:text-white"
          onClick={handleLoginClick}
        >
          Login
        </button>
        <button
          className="text-black  px-4 py-1 border border-white rounded-xl flex items-center justify-center hover:bg-green-600 hover:text-white"
          onClick={handleSignUpClick}
        >
          Sign Up
        </button>
      </div>
    </header>
  );
};

export default Header;
