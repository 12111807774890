import React, { useState } from "react";
import Icons from "./icons/Icons";
import { useNavigate } from "react-router-dom"; // Use useNavigate for programmatic navigation

const Login = () => {
  const [email, setEmail] = useState(""); // State for email
  const [password, setPassword] = useState(""); // State for password
  const [error, setError] = useState(""); // State for error handling
  const navigate = useNavigate(); // To navigate after successful login

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    const payload = {
      email,
      password,
    };

    try {
      const response = await fetch(
        "https://api.dev.civilianmarkhorpakistan.com/auth/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json(); // Parse the response
        localStorage.setItem("accessToken", data.data.accessToken);
        // Navigate to dashboard on successful login
        navigate("/dashboard");
      } else {
        const errorData = await response.json(); // Parse error response
        setError(errorData.message || "Login failed"); // Set error message
      }
    } catch (err) {
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-100 to-green-100 flex justify-center items-center">
      <div className="absolute top-4 left-4">
        <img
          src="/images/image11.svg"
          alt="Markhor Logo"
          className="h-12 w-12"
        />
      </div>
      <div className="z-[10] w-1/3">
        <div className="bg-white rounded-lg shadow-lg p-6 w-full">
          <div className="flex justify-center items-center mb-8">
            <Icons name="roundlock" />
            <h2 className="text-center text-2xl font-semibold text-gray-700 ms-3">
              Login
            </h2>
          </div>

          {/* Display error message if exists */}
          {error && (
            <div className="mb-4 text-red-500 text-center">{error}</div>
          )}

          <form className="space-y-4" onSubmit={handleLogin}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Update email state
                placeholder="name@company.com"
                className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Update password state
                placeholder="********"
                className="mt-1 w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                required
              />
            </div>
            <p className="text-end mt-4 text-sm text-gray-500">
              <a href="/login" className="text-green-600 font-semibold">
                Forgot Password?
              </a>
            </p>

            <button
              type="submit"
              className="w-full mt-6 py-3 text-white bg-green-500 rounded-lg hover:bg-green-600 transition"
              style={{
                background:
                  "linear-gradient(90.6deg, #38804F 0%, #1F5464 99.48%)",
              }}
            >
              Login
            </button>
          </form>
        </div>
        <p className="text-center mt-4 text-sm text-gray-500">
          Don’t have an account?{" "}
          <a href="/category" className="text-green-600 font-semibold">
            Sign Up
          </a>
        </p>
      </div>
      {/* union svg */}
      <div className="absolute bottom-4 right-2 z-[1]">
        <Icons name="union" />
      </div>
    </div>
  );
};

export default Login;
