import React, { useState } from "react";

const TaskDetailsModal = ({ task, onClose }) => {
  const [taskTime, setTaskTime] = useState("");
  const [description, setDescription] = useState("");
  const [screenshot, setScreenshot] = useState(null);
  const [screenshotUrl, setScreenshotUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const handleScreenshotUpload = async (file) => {
    setIsUploading(true); // Set upload state
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        "https://api.dev.civilianmarkhorpakistan.com/auth/upload-file",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        setScreenshotUrl(data?.data?.url); // Set the URL from the API response
      } else {
        console.error("Screenshot upload failed.");
      }
    } catch (error) {
      console.error("Error uploading screenshot:", error);
    } finally {
      setIsUploading(false); // Reset upload state
    }
  };
  const token = localStorage.getItem("accessToken"); 
  const handleConfirm = async () => {
    if (!taskTime || !description || !screenshotUrl) {
      console.error("All fields must be filled before submission.");
      return;
    }

    const payload = {
      taskId: task.id,
      taskTime: Number(taskTime), // Ensure taskTime is a number
      screenShortUrl: screenshotUrl,
      userDescription: description,
    };

    try {
      const response = await fetch(
        "https://api.dev.civilianmarkhorpakistan.com/complete-task",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": token,
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        alert("Task completed successfully!");
        onClose();
      } else {
        console.error("Failed to complete the task. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting task:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div className="bg-white h-[600px] rounded-lg shadow-lg w-96 p-6">
        <div className="flex justify-between items-center border-b pb-4">
          <h2 className="text-xl font-semibold">Add Task Details</h2>
          <button
            className="text-2xl font-bold text-black hover:text-gray-800"
            onClick={onClose}
          >
            &times;
          </button>
        </div>

        <div className="mt-6">
          <label htmlFor="screenshot-input" className="block text-black mb-2">
            Add Screenshot
          </label>
          <div className="flex items-center border rounded-lg p-3 bg-gray-100">
            <input
              id="screenshot-input"
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files[0];
                setScreenshot(file);
                handleScreenshotUpload(file);
              }}
              className="bg-transparent w-full text-gray-700 text-lg outline-none"
            />
          </div>
          {isUploading && (
            <p className="text-sm text-gray-500 mt-2">Uploading screenshot...</p>
          )}
        </div>

        <div className="mt-6">
          <label htmlFor="time-input" className="block text-black mb-2">
            Add Your Time
          </label>
          <div className="flex items-center border rounded-lg p-3 bg-gray-100">
            <input
              id="time-input"
              type="number"
              min="0"
              placeholder="0"
              value={taskTime}
              onChange={(e) => setTaskTime(e.target.value)}
              className="bg-transparent w-full text-gray-700 text-lg outline-none"
            />
            <span className="text-gray-400">hrs</span>
          </div>
        </div>

        <div className="mt-6">
          <label htmlFor="description-input" className="block text-black mb-2">
            Add Description
          </label>
          <div className="flex items-center border rounded-lg p-3 bg-gray-100">
            <input
              id="description-input"
              type="text"
              placeholder="Add Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="bg-transparent w-full text-gray-700 text-lg outline-none"
            />
          </div>
        </div>

        <button
          onClick={handleConfirm}
          disabled={
            !taskTime ||
            !description ||
            !screenshotUrl ||
            isUploading ||
            isNaN(Number(taskTime)) // Ensure taskTime is a valid number
          }
          className={`mt-48 w-full bg-gradient-to-r from-green-500 to-green-700 text-white font-semibold text-lg py-3 rounded-lg hover:scale-105 transform transition ${
            !taskTime ||
            !description ||
            !screenshotUrl ||
            isUploading ||
            isNaN(Number(taskTime))
              ? "opacity-50 cursor-not-allowed"
              : ""
          }`}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default TaskDetailsModal;
